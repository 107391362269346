* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.kontakt,
.products,
.sign-up {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: flex-start;
  font-size: 3rem;
}
.flex-wrapper{
  display: flex;
  flex-direction: column;
}

.kontakt {
  background-image: url('./assets/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('./assets/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 50px;
}

.popis {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.subpage-container {
  background: url('./images/img-11.jpg') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}



.sponzori {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
  margin-left: 25%;
}



.mapa {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  max-height: 70%;
  width: auto;
  height: auto;
}

.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 50%;
  max-height: 50%;
  height: 1000;
  width: auto;
  border-radius: 10px;
  display:block;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  overflow-y:hidden;
  margin-bottom: 10%;
  margin-top: 10%;

}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 10%;
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 10%;
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.galleryBackground {
  background: #242424;
  height: auto;
  width: auto;
  overflow-x: hidden;
  overflow-y:hidden;
  display:block
}

.slide {
  opacity: 0;
  transition: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);

}

.logo {
  max-height: 1%;
  width: 100%;
  height: 100%;
}

