  .subpage-container {
    background: url('../images/img-11.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  
  }
  
  .subpage-container > h1 {
    color: #fff;
    font-size: 8rem;
    text-shadow: 4px 4px 0px black;
    
  }
  
  .subpage-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    text-shadow: 2px 2px 0px black;
  }
  #map{
    margin-top: 5vh;
    width: 600px;
    height: 450px;
  }
  #mail-link{
    margin-top: 8px;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    text-shadow: 2px 2px 0px black;
  }
  @media screen and (max-width: 960px) {
    #map{
      width: 80vw;
      height: 60vw;
    }
    .subpage-container > h1{
      font-size: 4rem;
      margin-top: 10vh;
    }
    .subpage-container > p {
      font-size: 1.5rem;
    }
    #mail-link{
      font-size: 1.5rem;
    }
  }

  
