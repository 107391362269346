video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url('../images/imgJano-home.jpg') center center/cover no-repeat;
  height: calc(100vh - 80px); /* Subratcting navbar height */
  min-height: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  

}

table td#td-spacer{
  width: 30px;
}


.arrows {
  display: flex;
  position: flex-end;
  width: 60px;
  height: 80px;
  align-self: center;
}

.arrows path {
  stroke: #ffffff;
  fill: transparent;
  stroke-width: 3px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 { 
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}





#otvaracie-hod{
  /* box-shadow:  0px 0px 40px 10px rgba(0, 0, 0, 0.5), inset 0 0 0 1000px rgba(0, 0, 0, 0.5); */
}
#blur-wrap{
  
  height: 300px;
  width: 100%;
  overflow: hidden;
  margin-top: 100px;
}
#blur{

  background: url('../images/imgJano-home.jpg') center center/cover no-repeat;
  height: 300px;
  width: 100%;
  overflow: hidden;

  filter: blur(10px);
  box-shadow:  0px 0px 40px 10px rgba(0, 0, 0, 0.5), inset 0 0 0 1000px rgba(0, 0, 0, 0.5);

}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: 120px; 
  text-shadow: 4px 4px 0px black;
  
}

.text {
  margin-top: 8px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-shadow: 2px 2px 0px black;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 11vw;

  }
}


@media screen and (max-width: 500px) {
  .hero-container{
    min-height: 600px;
  }
  .hero-container > h1 {
    font-size: 11vw;
    margin-top: 100px;
    

  }

  .text{
    font-size: 6.5vw;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
